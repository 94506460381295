import React, { useEffect, useRef } from "react";
import "./LoopingVideo.css";

function LoopingVideo({ onVideoLoad }) {
  const videoRef = useRef(null);
  const hasLoaded = useRef(false);

  useEffect(() => {
    const prefetchLink = document.createElement("link");
    prefetchLink.href =
      "https://plix-videos.azureedge.net/videos-public/pedestrians.mp4";
    prefetchLink.rel = "prefetch";
    prefetchLink.as = "video";

    document.head.appendChild(prefetchLink);

    // Add animation class after video loads
    const video = videoRef.current;
    if (video) {
      // If the video is already loaded (browser cached it)
      if (video.readyState >= 3 && !hasLoaded.current) {
        hasLoaded.current = true;
        video.classList.add("animate-zoom");
        onVideoLoad();
      }

      // Still add the event listener for normal loading
      video.addEventListener("canplay", () => {
        if (!hasLoaded.current) {
          hasLoaded.current = true;
          video.classList.add("animate-zoom");
          onVideoLoad();
        }
      });
    }

    return () => {
      document.head.removeChild(prefetchLink);
      if (video) {
        video.removeEventListener("canplay", () => {});
      }
    };
  }, [onVideoLoad]);

  return (
    <div className="video-background">
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="video-fill"
      >
        <source
          src="https://plix-videos.azureedge.net/videos-public/pedestrians.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default LoopingVideo;
