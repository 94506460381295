import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Make sure your CSS is properly imported

function CareersPage() {
  useEffect(() => {
    // Scroll to top when component mounts (important for mobile)
    window.scrollTo(0, 0);

    // Single fade-in for the entire page
    const pageElement = document.querySelector(".careers-page");
    if (pageElement) {
      setTimeout(() => {
        pageElement.classList.add("fade-in");
      }, 100);
    }
  }, []);

  return (
    <div className="careers-page">
      <h1>Careers at Plix</h1>
      <p className="career-description">
        If you like hacking on massive scale computer vision systems, embedded
        systems, and want to be part of building a legendary company, we're
        hiring:
      </p>
      <div className="button-container">
        <a
          href="https://jobs.gem.com/plixai"
          className="job-openings-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Job Openings
        </a>
      </div>
      <footer className="footer">
        <Link to="/" className="home-button">
          <i className="fas fa-arrow-left"></i> Back to Home
        </Link>
      </footer>
    </div>
  );
}

export default CareersPage;
