import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import LoopingVideo from "./LoopingVideo";
import CareersPage from "./Careers";
import "./App.css";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [contentOpacity, setContentOpacity] = useState(0);
  const appRef = useRef(null);

  const handleVideoLoad = () => {
    setIsLoading(false);
    setTimeout(() => {
      setContentOpacity(1);
    }, 200);
  };

  useEffect(() => {
    if (!isLoading) {
      setContentOpacity(1);
    }
  }, [isLoading]);

  useEffect(() => {
    // Set dark mode as the only option (removing theme toggle functionality)
    document.documentElement.setAttribute("data-theme", "dark");

    // Fix for mobile viewport height issues
    const handleResize = () => {
      // For mobile browsers, particularly iOS Safari
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      // Ensure the app fills the viewport appropriately
      if (appRef.current) {
        appRef.current.style.height = `${window.innerHeight}px`;

        // Add class for small screens to adjust layout
        if (window.innerWidth <= 480) {
          appRef.current.classList.add("small-screen");
        } else {
          appRef.current.classList.remove("small-screen");
        }

        // Add extra top padding for very small heights
        if (window.innerHeight <= 600) {
          appRef.current.style.paddingTop = "90px";
        }
      }
    };

    // Initial setup
    handleResize();

    // Add fade-in animations
    const elements = document.querySelectorAll(".fade-element");
    elements.forEach((element, index) => {
      setTimeout(() => {
        element.classList.add("fade-in");
      }, 100 + index * 150);
    });

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
      <div ref={appRef} className="App" style={{ opacity: contentOpacity }}>
        <LoopingVideo onVideoLoad={handleVideoLoad} />
        <div className="login-button-container">
          <a
            href="https://redisland.plix.ai"
            className="login-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Log In</span>
          </a>
          <a
            href="https://billing.stripe.com/p/login/28o17k5LSbQp5Ne7ss"
            className="manage-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Manage</span>
          </a>
        </div>
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <header className="fade-element">
                    <div className="header-title">
                      <span className="company-name">PLIX</span>
                      <h1 className="title">AI Body Cameras</h1>
                    </div>
                  </header>
                  <div className="mission-statement fade-element">
                    <div className="mission-content">
                      <h2>Our Mission</h2>
                      <p>
                        To increase the safety, security, and efficiency of the
                        workforce that serves our communities and businesses.
                      </p>
                    </div>
                  </div>
                  <div className="core-values">
                    <div className="value-card fade-element">
                      <h3>Liability Protection</h3>
                      <p>
                        Comprehensive documentation and evidence preservation to
                        protect your organization and workforce.
                      </p>
                    </div>
                    <div className="value-card fade-element">
                      <h3>Early Intervention</h3>
                      <p>
                        Real-time alerts and AI-powered insights to prevent
                        incidents before they escalate.
                      </p>
                    </div>
                    <div className="value-card fade-element">
                      <h3>Workforce Management</h3>
                      <p>
                        Advanced tools for efficient oversight, training, and
                        operational excellence.
                      </p>
                    </div>
                  </div>
                  <div className="button-container fade-element">
                    <a
                      href="https://calendar.app.google/Y8stg6ndnSegDVvU9"
                      className="access-button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                    <Link to="/careers" className="careers-link">
                      Careers
                    </Link>
                  </div>
                </>
              }
            />
            <Route path="/careers" element={<CareersPage />} />
          </Routes>
        </div>
        <div className="contact-container fade-element">
          <span className="contact-link">plix@plix.ai</span>
        </div>
      </div>
    </Router>
  );
}

export default App;
